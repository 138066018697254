import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField, NamedLink } from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';
import FieldCodeInput from '../../../components/FieldCodeInput/FieldCodeInput';
import FieldInternationalPhoneNumberInput from '../../../components/FieldInternationalPhoneNumberInput/FieldInternationalPhoneNumberInput';
import { signUpSteps } from '../../../util/signUpSteps';


import css from './SignupForm.module.css';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
        validationCodeInProgress,
        validationCodeError,
        codeVerificationInProgress,
        codeVerificationError,
        signupStep,
        goToSignupStep,
        requestValidationCode,
        validateCode,
      } = formRenderProps;

      const { userType } = values || {};

      const sendVerificationCode = (e) => {
        e.preventDefault();
        requestValidationCode({ phoneNumber: values.phoneNumber });
      }
      const handleCodeVerification = (e) => {
        e.preventDefault();
        validateCode({
          verificationCode: values.smsCode,
          phoneNumber: values.phoneNumber,
          formValues: values,
        });
      }
      // phone number
      const phoneNumber = intl.formatMessage({
        id: 'SignupForm.phoneNumber',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberRequired',
      });
      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);


      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

      const noUserTypes = !userType && !(userTypes?.length > 0);
      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const loginLink =
        <NamedLink
          name="LoginPage"
          className={css.loginLink}
        >
          <FormattedMessage id="SignupForm.login" />
        </NamedLink>

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {signupStep === signUpSteps.stepPhoneInput &&
            <div>
              <h2>
                <FormattedMessage id="SignupForm.welcome" />
              </h2>
              <FieldInternationalPhoneNumberInput
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                defaultCountry="AE"
                withCountryCallingCode
                countryCallingCodeEditable={false}
                international
                label={phoneNumber}
                validate={phoneNumberRequired} />
              {validationCodeError &&
                <label className={css.error}>
                  {validationCodeError}
                </label>}
              <p>
                <FormattedMessage id="SignupForm.phoneNumberInfo" />
              </p>
              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.termsText}>
                    {/* <FormattedMessage
                      id="SignupForm.termsAndConditionsAcceptText"
                      values={{ termsLink }}
                    /> */}
                  </span>
                </p>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  inProgress={submitInProgress || validationCodeInProgress}
                  disabled={submitDisabled}
                  onClick={sendVerificationCode}>
                  <FormattedMessage id="SignupForm.continue" />
                </PrimaryButton>
              </div>
            </div>
          }
          {signupStep === signUpSteps.stepSMSCodeInput &&
            <div>
              <p>
                <FormattedMessage id="SignupForm.enterCode" />
              </p>
              <FieldCodeInput
                className={css.smsCode}
                name="smsCode"
                id={formId ? `${formId}.smsCode` : 'smsCode'}
              />
              {codeVerificationError &&
                <label className={css.error}>
                  {codeVerificationError}
                </label>}
              <PrimaryButton
                className={css.submitButton}
                type="submit" inProgress={submitInProgress || codeVerificationInProgress}
                disabled={submitDisabled}
                onClick={handleCodeVerification}>
                <FormattedMessage id="SignupForm.verify" />
              </PrimaryButton>
            </div>
          }
          {signupStep === signUpSteps.stepSignUp &&

            <div className={css.signupContainer}>
              <h2 className={css.title}>
                <FormattedMessage id="SignupForm.welcome" />
              </h2>
              <FieldSelectUserType
                name="userType"
                userTypes={userTypes}
                hasExistingUserType={!!preselectedUserType}
                intl={intl}
              />

              {showDefaultUserFields ? (
                <div className={css.defaultUserFields}>
                  <div className={css.name}>
                    <FieldTextInput
                      className={css.firstNameRoot}
                      type="text"
                      id={formId ? `${formId}.fname` : 'fname'}
                      name="fname"
                      autoComplete="given-name"
                      label={intl.formatMessage({
                        id: 'SignupForm.firstNameLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.firstNamePlaceholder',
                      })}
                      validate={validators.required(
                        intl.formatMessage({
                          id: 'SignupForm.firstNameRequired',
                        })
                      )}
                      isColumnOnly
                    />
                    <FieldTextInput
                      className={css.lastNameRoot}
                      type="text"
                      id={formId ? `${formId}.lname` : 'lname'}
                      name="lname"
                      autoComplete="family-name"
                      label={intl.formatMessage({
                        id: 'SignupForm.lastNameLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.lastNamePlaceholder',
                      })}
                      validate={validators.required(
                        intl.formatMessage({
                          id: 'SignupForm.lastNameRequired',
                        })
                      )}
                      isColumnOnly
                    />
                  </div>

                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    autoComplete="email"
                    label={intl.formatMessage({
                      id: 'SignupForm.emailLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.emailPlaceholder',
                    })}
                    isColumnOnly
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />

                  <UserFieldDisplayName
                    formName="SignupForm"
                    className={css.row}
                    userTypeConfig={userTypeConfig}
                    intl={intl}
                  />

                  <FieldTextInput
                    className={css.password}
                    type="password"
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    autoComplete="new-password"
                    label={intl.formatMessage({
                      id: 'SignupForm.passwordLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.passwordPlaceholder',
                    })}
                    validate={passwordValidators}
                    isColumnOnly
                  />

                  <UserFieldPhoneNumber
                    formName="SignupForm"
                    className={css.row}
                    userTypeConfig={userTypeConfig}
                    intl={intl}
                  />
                </div>
              ) : null}

              {showCustomUserFields ? (
                <div className={css.customFields}>
                  {userFieldProps.map(fieldProps => (
                    <CustomExtendedDataField {...fieldProps} formId={formId} />
                  ))}
                </div>
              ) : null}
              <p className={css.login}>
                <FormattedMessage id="SignupForm.loginContent" values={{ login: loginLink }} />
              </p>

              <div className={css.bottomWrapper}>
                {/* {termsAndConditions} */}
                <PrimaryButton
                  className={css.ctaButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SignupForm.continue" />
                </PrimaryButton>
              </div>
            </div>
          }
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
